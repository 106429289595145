// Externals
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Selectors
import { policyDocumentsSelector } from '../../store/selectors/documents';

// Styles
import './Button.scss';

// Icon
import { ReactComponent as DocumentsIcon } from '../../static/documents.svg' 

// Documents Button
const DocumentsButton = (props) => {
	const { policyNumber } = props;
	const documentsList = useSelector(state => policyDocumentsSelector(state, policyNumber));
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/documents/' + policyNumber)
	};

	return (policyNumber && documentsList.length > 0) && (
		<button className='documents-button' onClick={handleClick}>Documents <DocumentsIcon /></button>
	);
};

// Export
export default DocumentsButton;
