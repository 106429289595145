// Externals
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import Container from '../components/Layout/Container';
import PolicyHeader from '../components/Widgets/PolicyHeader';

// Forms
import FormSelectPayments from '../components/Forms/SelectPayments';
import FormMakePayment from '../components/Forms/MakePayment';
import FormFinalisePayment from '../components/Forms/MakePaymentFinalise';

// Actions
import { clearSelectedPayNowTransactions, resetPayNowControl } from '../store/actions/payment';

// Selectors
import { overduePoliciesSelector } from '../store/selectors/policy';
import { payNowPoliciesPendingPaymentSelector } from '../store/selectors/payment';

// Pages
import Error404 from '../pages/Error404';

// Make Payment Page
const MakePayment = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { policyNumber } = useParams();
	const policy = useSelector(state => state.policy.policySummaries[policyNumber]);
	const overduePolicies = useSelector(overduePoliciesSelector);

	const [ searchParams ] = useSearchParams();

	const [ captureTransaction, setCaptureTransaction ] = useState(false);
	const [ finaliseTransaction ] = useState(searchParams.get('transactionidentifier') ? true : false);
	const policyPaymentPending = useSelector(payNowPoliciesPendingPaymentSelector);

	useEffect(() => {
		return () => {
			// empty the selected transactions when component unmounted
			dispatch(clearSelectedPayNowTransactions());
			dispatch(resetPayNowControl());
		};
	}, [ dispatch ]);

	const handlePayment = () => {
		setCaptureTransaction(true);
	};

	const handleBack = () => {
		setCaptureTransaction(false);
		window.scrollTo({ top: 0, behaviour: 'smooth'});
	};

	const handleCancel = () => {
		navigate('/', { replace: true })
	};

	// Only permit payment if :policyNumber has overdue payments
	if (!overduePolicies.includes(policyNumber)) {
		return <Error404 />;
	}
	
	// Don't permit payment if :policyNumber has pending payments
	if (!finaliseTransaction && policyPaymentPending.includes(policyNumber)) {
		return <Navigate to='/' replace />
	}

	// Render
	return (
		<>
			<PolicyHeader />

			<Container>
				<div className='page-make-payment'>

					{ (!captureTransaction && !finaliseTransaction) && <>

						<h2>Make a Payment</h2>

						<p><strong>Outstanding payments for your {policy.ProductName} policy.</strong></p>

						<p>You're one step closer to maintaining cover. Please see below the total amount outstanding for your policy which you can pay online now.</p>

						<p>Please note:</p>

						<ul>
							<li>Your policy is at risk of lapsing until all outstanding payments are settled</li>
							<li>All outstanding payments must be made using a credit card</li>
						</ul>

						<FormSelectPayments handlePayment={handlePayment} handleCancel={handleCancel} /> 

					</>}

					{ (captureTransaction && !finaliseTransaction) && <>

						<h2>Make a Payment</h2>

						<FormMakePayment handleBack={handleBack} />
					
					</>}

					{ finaliseTransaction && <>
					
						<FormFinalisePayment />

					</>}

				</div>
			</Container>
		</>
	);
};

export default MakePayment;
