
import config from '../../config';
import { countries } from '../../store/constants';

const defaultCountryId = config.DEFAULT_COUNTRY_ID;

export const firstNameSelector = (state) => state.contact.FirstName;

export const addressLookupSelector = (state) => {
	const a = state.contact.pendingUpdate.ResidentialAddress;
	if ((a.AddressLine && (a.Suburb || a.City) && a.State && a.PostCode) && (a.AddressLine.length > 0 && (a.Suburb.length > 0 || a.City.length > 0) && a.State.length > 0 && a.PostCode)) {
		return a.AddressLine + ' ' + (a.Suburb ? a.Suburb : a.City) + ' ' + a.State + ' ' + a.PostCode
	} else {
		return null;
	}
}

export const postalAddressLookupSelector = (state) => {
	const a = state.contact.pendingUpdate.PostalAddress;
	if ((a.AddressLine && (a.Suburb || a.City) && a.State && a.PostCode) && (a.AddressLine.length > 0 && (a.Suburb.length > 0 || a.City.length > 0) && a.State.length > 0 && a.PostCode)) {
		return a.AddressLine + ' ' + (a.Suburb ? a.Suburb : a.City) + ' ' + a.State + ' ' + a.PostCode
	} else {
		return null;
	}
}

export const contactEditableSelector = state => state.contact.PostalAddress.CountryId === config.DEFAULT_COUNTRY_ID;

export const contactSelector = (state) => {
	return {
		homeAddress: state.contact.ResidentialAddress.AddressLine,
		townCity: state.contact.ResidentialAddress.Suburb ? 
			state.contact.ResidentialAddress.Suburb : state.contact.ResidentialAddress.City,
		state: state.contact.ResidentialAddress.State,
		postcode: state.contact.ResidentialAddress.PostCode,
		postalAddress: state.contact.PostalAddress.AddressLine + ' '
				+ state.contact.PostalAddress.Suburb + ' '
				+ state.contact.PostalAddress.State + ' '
				+ state.contact.PostalAddress.PostCode + ' ' 
				+ (state.contact.PostalAddress.CountryId !== defaultCountryId ? countries[state.contact.PostalAddress.CountryId] : ''),
		mobilePhone: state.contact.MobilePhone,
		landlinePhone: state.contact.HomePhone,
		email: state.contact.Email,
		FirstName: state.contact.FirstName,
		LastName: state.contact.LastName
	}
};

export const editContactSelector = (state) => {
	// compare residential and postal addresses
	const postalSameAsResidential = () => {
		const a = state.contact.pendingUpdate;
		if (a.ResidentialAddress.AddressLine !== a.PostalAddress.AddressLine
			|| a.ResidentialAddress.Suburb !== a.PostalAddress.Suburb
			|| a.ResidentialAddress.State !== a.PostalAddress.State
			|| a.ResidentialAddress.PostCode !== a.PostalAddress.PostCode) {
				return false;
		}
		else return true;
	}
	return {
		ResidentialAddress: state.contact.pendingUpdate.ResidentialAddress,
		PostalAddress: state.contact.pendingUpdate.PostalAddress,
		MobilePhone: state.contact.pendingUpdate.MobilePhone,
		HomePhone: state.contact.pendingUpdate.HomePhone,
		Email: state.contact.pendingUpdate.Email,
		postalSame: postalSameAsResidential(),
		FirstName: state.contact.FirstName,
		LastName: state.contact.LastName
	}
};

export const updateContactErrorsSelector = state => state.contact.showErrorMessage;

export const contactUpdateInProgress = state => state.contact.loading;
