
// Actions
import { setPolicyStatusPaymentPending } from '../actions/policy';
import { markPolicyPayNowPending } from '../actions/payment';

// Payment Middleware
const paymentMiddleware = store => next => action => {
	if (action.type === 'payment/FINALISE_PAY_NOW_TRANSACTION/fulfilled') {

		const policyNumber = action.payload.PolicyNumber;

		// mark this policy as payment pending/processing
		store.dispatch(markPolicyPayNowPending(policyNumber));
		
		// set the policy status to "Payment Processing"
		store.dispatch(setPolicyStatusPaymentPending(policyNumber));

	}
	next(action)
}

// Export
export default paymentMiddleware;
