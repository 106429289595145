import React from 'react';

export const creditCard = 0;
export const directDebit = 1;

const PaymentMethodSwitch = ({ paymentMethod, setPaymentMethod }) => {
    
    return (
        <div className='payment-method-switch'>
            <div className={'credit-card-method' + (paymentMethod === creditCard ? ' active': '') } onClick={() => setPaymentMethod(creditCard)}>
                Credit Card
            </div>
            <div className={'direct-debit-method' + (paymentMethod === directDebit ? ' active' : '') } onClick={() => setPaymentMethod(directDebit)}>
                Direct Debit
            </div>
        </div>
    )
}

export default PaymentMethodSwitch;
