// Utility
import { sortDateDescending, sortDateAscending } from '../../util/sort';

// return policy documents object as array
export const policyDocumentsSelector = (state, policyNumber, sort='desc') => (
	state.documents.policyDocuments[policyNumber] && 
		// slice() takes a writeable copy of the redux state
		state.documents.policyDocuments[policyNumber].slice().sort((a, b) => {
			if (sort === 'desc') return sortDateDescending(a.CreatedDate, b.CreatedDate);
			else return sortDateAscending(a.CreatedDate, b.CreatedDate);
		})
);

// return the document type (pdf or eml)
export const documentTypeSelector = (state, policyNumber, documentNumber) => {
	const document = state.documents.policyDocuments[policyNumber] && state.documents.policyDocuments[policyNumber].find(
		document => document.CorrespondenceId === documentNumber
	);
	return (document && document.Type) || null;
};
