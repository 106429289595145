// Update Payment Details, Pay Now

// ENV Config
import config from '../../config';

// Base Host for API calls
const apiHost = config.API_HOST;

export const apiGetPaymentAuth = async (payload, bearerToken, rejectWithValue) => {
	const url = apiHost + '/api/v1/updatepaymentdetails/ValidateAndGetTokens';
	const body = JSON.stringify(payload);

	const response = await fetch(url, {
		method: 'POST',
		headers: {
			Authorization: 'Bearer ' + bearerToken,
			'Content-Type': 'application/json'
		},
		body: body
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

export const apiUpdatePaymentDetails = async (payload, bearerToken, rejectWithValue) => {
	const url = apiHost + '/api/v1/updatepaymentdetails/ProcessAfterRedirect';

	const response = await fetch(url, {
		method: 'POST',
		headers: {
			Authorization: 'Bearer ' + bearerToken,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(payload)
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

// Retrieve list of outstanding payments
export const apiGetPayNowPaymentControl = async (args, bearerToken, rejectWithValue) => {
	const url = apiHost + '/api/v1/PayNow/getpaymentcontrol';

	const params = '?clientPolicyNumber=' + args.clientPolicyNumber
		+ '&ClientPortalLoginId=' + args.ClientPortalLoginId
	
	const response = await fetch(url + params, {
		method: 'GET',
		headers: {
			Authorization: 'Bearer ' + bearerToken,
			'Content-Type': 'application/json'
		}
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

// Azure-88692: Retrieve list of missed regular collections during Extended Dishonour Period
export const apiGetMissedRegularCollections = async (args, bearerToken, rejectWithValue) => {
	const url = apiHost + '/api/v1/PayNow/missedregularcollections';

	const params = '?clientPolicyNumber=' + args.clientPolicyNumber
		+ '&clientPortalLoginId=' + args.ClientPortalLoginId
		+ '&calculationStartDate=' + ((args.calculationStartDate !== undefined && args.calculationStartDate != null) ? args.calculationStartDate : '')
		+ '&calculationEndDate=' + ((args.calculationEndDate !== undefined && args.calculationEndDate != null) ? args.calculationEndDate : '');
	
	const response = await fetch(url + params, {
		method: 'GET',
		headers: {
			Authorization: 'Bearer ' + bearerToken,
			'Content-Type': 'application/json'
		}
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

// Azure-88692: Get list of outstanding Payment Arrangements during Extended Dishonour Period
export const apiGetOutstandingPaymentOverrides = async (args, bearerToken, rejectWithValue) => {
	const url = apiHost + '/api/v1/PayNow/outstandingpaymentoverrides';

	const params = '?clientPolicyNumber=' + args.clientPolicyNumber
		+ '&clientPortalLoginId=' + args.ClientPortalLoginId;
	
	const response = await fetch(url + params, {
		method: 'GET',
		headers: {
			Authorization: 'Bearer ' + bearerToken,
			'Content-Type': 'application/json'
		}
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

export const apiFinalisePayNowTransaction = async (params, bearerToken, rejectWithValue) => {
	const url = apiHost + '/api/v1/PayNow/completecollection';

	const response = await fetch(url, {
		method: 'POST',
		headers: {
			Authorization: 'Bearer ' + bearerToken,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(params)
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}
