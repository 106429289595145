import React from "react";
import { useSelector } from 'react-redux';

// Selectors
import { headingSelector } from '../../../store/selectors/brand';

// Utility
import { formatDate } from '../../../util/formats';

// Components
import Table from "../../Layout/Table";
import TableRow from "../../Layout/TableRow";

const DetailPolicySummary = ({policy}) => {
	const sectionHeading = useSelector(state => headingSelector(state, 'policySummary'));
    return (<>
        <div className='detail-heading'><h3>{sectionHeading}</h3></div>
        <Table className='policy-details-summary'>
                <TableRow heading={'Policy Number' } value={policy.PolicySummary.PolicyNumber}/>
                <tr className={'policy-owner-name'}><td key={1}>Policy Owner</td><td key={2} className='gfs-app-tl-mask'><div>{policy.Owner.Title + ' ' + policy.Owner.FirstName + ' ' + policy.Owner.Lastname}</div></td></tr>
                <TableRow heading={'Policy Status'} value={policy.PolicyStatus.Description} />
                <TableRow heading={'Commencement Date'} value={formatDate(policy.PolicySummary.FirstCollectionDate)} />
                <TableRow heading={'Renewal Date'} value={formatDate(policy.PolicySummary.RenewalDate)} />
         </Table>
    </>)
}

export default DetailPolicySummary;
