// Utility
import { formatDate } from '../../util/formats';
import { sortDateAscending } from '../../util/sort';

// return policy summary object as array, sorted by PolicyTypeID and Policy Commencement Date (aka SaleCompletedDate)
export const policySummariesSelector = (state) => Object.keys(state.policy.policySummaries).map(key => 
	state.policy.policySummaries[key]
).sort((a, b) => {
	return sortDateAscending(a.SaleCompletedDate, b.SaleCompletedDate);
}).sort((a, b) => {
	return a.ProductType.ProductTypeId - b.ProductType.ProductTypeId;
});

// return the policy detail
export const policyDetailSelector = (state, policyNumber) => state.policy.policyDetails[policyNumber];

// used as a guard for /policy/:policyNumber URIs
export const validPoliciesSelector = state => Object.keys(state.policy.policySummaries);

// used as a guard for /payment/:policyNumber URIs
export const overduePoliciesSelector = state => Object.keys(state.policy.outstandingPayments);

// return outstanding/overdue payment details for banner, keyed by policy
export const overduePaymentsBannerSelector = (state) => {
	const policySummaries = state.policy.policySummaries;
	if (Object.keys(policySummaries).length === 0) return null;
	
	const outstandingPayments = Object.keys(state.policy.outstandingPayments);
	const pendingPayments = state.payment.payNowPoliciesPendingPayment;
	if (outstandingPayments.length === 0 && pendingPayments.length === 0) return null;

	const payable = outstandingPayments.filter(policyNumber => !pendingPayments.includes(policyNumber)).map(policyNumber => {
		const transactions = state.policy.outstandingPayments[policyNumber];
		return {
			policyNumber: policyNumber,
			policyName: policySummaries[policyNumber].ProductName,
			overdueCount: transactions.length,
			totalDue: transactions.reduce((sum, item) => sum + item.Amount, 0)
		}
	});
	const pending = pendingPayments.map(policyNumber => ({
		policyNumber: policyNumber,
		policyName: policySummaries[policyNumber].ProductName,
		hasPending: true
	}));
	return [...payable, ...pending];
};

// return outstanding/overdue payment details for policy 
// (used by Pay Now to generate the selectable list of payments)
export const overduePolicyPaymentsSelector = (state, policyNumber) => {
	if (Object.keys(state.policy.outstandingPayments).length === 0) return null;
	const p = state.policy.outstandingPayments[policyNumber];
	return p.length === 0 ? null 
		: p.map(payment => ({
			transactionId: payment.TransactionId,
			dueDate: formatDate(payment.RegularCollectionDate),
			sortDate: payment.RegularCollectionDate,
			amount: payment.Amount,
			CalculatedCollectionDetails: payment.CalculatedCollectionDetails, //Azure-88692: Calculated details for EDP Missed Regular Collections
			PaymentOverrideId: payment.PaymentOverrideId //Azure-88692: Any outstanding Payment Overrides will have a payment override ID populated.
		})
	).sort((a, b) => {
		return sortDateAscending(a.sortDate, b.sortDate);
	});
};

// return true if the policyNumber has overdue payments which are not currently in process
export const policyHasOverduePaymentsSelector = (state, policyNumber) => {
	return Object.keys(state.policy.outstandingPayments).filter(
		policyNumber => !state.payment.payNowPoliciesPendingPayment.includes(policyNumber)
	).includes(policyNumber);
};
