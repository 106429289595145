// Externals
import { createSlice } from '@reduxjs/toolkit';

// Actions 
import { 
	getPolicyDocumentList,
	clearPolicyDocuments } from '../actions/documents';

// Initial State
const initialState = {
	loading: false,
	policyDocuments: {}
};

// Policy Reducer
const policy = createSlice({
	name: 'documents',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
	
		// Load the policy documentlist
		builder.addCase(getPolicyDocumentList.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(getPolicyDocumentList.rejected, (state, action) => {
			state.loading = false;
		});
		builder.addCase(getPolicyDocumentList.fulfilled, (state, action) => {
			state.loading = false;
			state.policyDocuments[action.meta.arg] = action.payload.map(document => 
				({...document, Type: document.Name.replace(/^.+\.([a-z]{3})$/, '$1')})
			);
		});

		// Clear the policy documents (for Logout)
		builder.addCase(clearPolicyDocuments, (state, action) => {
			state.policyDocuments = initialState.policyDocuments;
		});

	}
});

// Exports
export default policy.reducer;
