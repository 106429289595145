
// Externals
import { createSlice } from '@reduxjs/toolkit';
import { toTitleCase } from '../../util/formats';

// Actions 
import { getAddressMatches, getFormattedAddress, resetAddressMatches } from '../actions/address';

// Initial State
const initialState = {
	addressMatches: [],
	postalAddressMatches: []
};

// Address Lookup Reducer
const address = createSlice({
	name: 'address',
	initialState,
	reducers: {},
	extraReducers: (builder) => {

		// Store the address lookup matches 
		builder.addCase(getAddressMatches.pending, (state, action) => {
			//
		});
		builder.addCase(getAddressMatches.rejected, (state, action) => {
			//
		});
		builder.addCase(getAddressMatches.fulfilled, (state, action) => {
			if(action.payload.isResidential){
				state.addressMatches = action.payload.addresses.map(address => toTitleCase(address));
			} else {
				state.postalAddressMatches = action.payload.addresses.map(address => toTitleCase(address));
			}
		});
	
		// Receive the formatted address, after user makes a choice from the lookup
		builder.addCase(getFormattedAddress.pending, (state, action) => {
			//
		});
		builder.addCase(getFormattedAddress.rejected, (state, action) => {
			//
		});
		builder.addCase(getFormattedAddress.fulfilled, (state, action) => {
			// state.addressMatches = action.payload;
			// this return data is captured in middleware/contact.js
		});

		builder.addCase(resetAddressMatches, (state, action) => {
			state.addressMatches = initialState.addressMatches;
			state.postalAddressMatches = initialState.postalAddressMatches;
		});

	}
});

// Exports
export default address.reducer;
