// Externals
import React, { Suspense, lazy } from 'react';

// Customised App - Real Insurance
const RealInsuranceApp = lazy(() => import('./RealInsurance/App'));

// Customised App - Australian Seniors
const AustralianSeniorsApp = lazy(() => import('./AustralianSeniors/App'));

// Customised App - New Zealand Seniors
// const NZSeniorsApp = lazy(() => import('./NZSeniors/App'));

// Brand HOC to return selected brand
const Brand = (props) => {
	const { brand } = props;

	// Configured Themes
	const themes = {
		real: <RealInsuranceApp {...props} />,
		seniors: <AustralianSeniorsApp {...props} />,
		// nzseniors: <NZSeniorsApp {...props} />,
		// etc
	};

	// Render
	return (
		<Suspense fallback={<></>}>
			{((brand && themes[brand]) && themes[brand]) || <p>No theme found</p> }
		</Suspense>
	);

};

// Exports
export default Brand;
