import { datadogRum } from '@datadog/browser-rum';

// ENV Config
import config from '../config';

export const initAppMonitoring = () => {
        
    datadogRum.init({
        applicationId: config.DATADOG_APPLICATIONID,
        clientToken: config.DATADOG_CLIENTTOKEN,
        site: 'datadoghq.com',
        service: config.DATADOG_SERVICE_NAME,
        env: config.ENVIRONMENT,
        
        // Specify a version number to identify the deployed version of your application in Datadog 
        // We will implement version as part of config rework
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
    });

    datadogRum.startSessionReplayRecording();
}