// Externals
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

// Selectors
import { policyDetailSelector } from '../../store/selectors/policy';
import { policyDocumentsSelector } from '../../store/selectors/documents';

// Components
import PolicyTitle from '../Policy/Title';

// Utility
import { formatDate } from '../../util/formats';

// Styles
import './List.scss';

// Documents List
const DocumentList = (props) => {
	const { policyNumber } = props;
	const [ documentSort, setDocumentSort ] = useState('desc');

	const policy = useSelector(state => policyDetailSelector(state, policyNumber));
	const documentsList = useSelector(state => policyDocumentsSelector(state, policyNumber, documentSort));

	const handleOpenDocument = (id) => {
		window.open('/documents/' + policyNumber + '/' + id)
	}

	const handleDocumentSort = () => {
		setDocumentSort(documentSort === 'desc' ? 'asc' : 'desc');
	}

	return (
		<div className='documents-list'>
			<PolicyTitle policy={policy} />
			<p>You can view past communications below:</p>
			<div className='table-wrapper'>
				<table className='desktop'>
					<tbody>
						<tr>
							<th><button onClick={handleDocumentSort}>Date</button></th>
							<th>Document</th>
							<th>Size</th>
							<th>Type</th>
						</tr>
						{documentsList.map((document, index) => (
							<tr key={index}>
								<td>{formatDate(document.CreatedDate)}</td>
								<td>
									<button onClick={() => handleOpenDocument(document.CorrespondenceId)}>
										{document.Name}
									</button>
								</td>
								<td>{document.Size}kb</td>
								<td>
									{document.Type === 'eml' && 'Email'}
									{document.Type === 'pdf' && 'Letter'}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<table className='mobile'>
					<tbody>
						<tr key='headings'>
							<th>Document</th>
							<th>Size</th>
							<th><button onClick={handleDocumentSort}>Date</button></th>
						</tr>
						{documentsList.map((document, index) => (<Fragment key={index}>
							<tr key={'document-' + index}>
								<td colSpan='3'>
									<button onClick={() => handleOpenDocument(document.CorrespondenceId)}>
										{document.Name}
									</button>
								</td>
							</tr>
							<tr key={'meta-' + index} className='meta'>
								<td>
									{document.Type === 'eml' && 'Email'}
									{document.Type === 'pdf' && 'Letter'}
								</td>
								<td>{document.Size}kb</td>
								<td>{formatDate(document.CreatedDate)}</td>
							</tr>
						</Fragment>))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

// Export
export default DocumentList;
