// Externals
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

// Helpers for fetching async data
import { apiGetPolicyDetails, apiGetPolicyOutstandingPayments } from '../async/policy';
import { apiGetMissedRegularCollections, apiGetOutstandingPaymentOverrides } from '../async/payment';

// Add policy summary (from contact API)
export const addPolicySummaryFromContact = createAction('policy/ADD_POLICY_SUMMARY_FROM_CONTACT');

// Get policy details
export const getPolicyDetail = createAsyncThunk('policy/GET_POLICY_DETAIL', 
	// TODO: abortController
	async (policyNumber, { getState, rejectWithValue }) => {
		const customerLoginId = getState().auth.customerLoginId;
		const token = getState().auth.bearerToken;
		return apiGetPolicyDetails(policyNumber, customerLoginId, token, rejectWithValue);
	}
);

// Get outstanding payments for policy
export const getPolicyOutstandingPayments = createAsyncThunk('policy/GET_PAYMENTS_OUTSTANDING', 
	async (policyNumber, { getState, dispatch, rejectWithValue }) => {
		const token = getState().auth.bearerToken;
		const params = {
			ClientPortalLoginId: getState().auth.customerLoginId,
			clientPolicyNumber: policyNumber
		}
		var outstandingPaymentsResponse = await apiGetPolicyOutstandingPayments(params, token, dispatch, rejectWithValue);

		//Azure-88692: During the Extended Dishonour Period all outstanding payment must be paid including any regular collections missed during EDP.
		const edpStatusId = getState().policy.policyDetails[policyNumber].PolicySummary.ExtendedDishonourPeriodStatusId;
		const isPolicyInEdp = (edpStatusId === 1 || edpStatusId === 2); //Active or Active Payment Pending
		if(isPolicyInEdp)
		{
			var missedRegularCollections = await apiGetMissedRegularCollections(params, token, dispatch, rejectWithValue);
			var outstandingPaymentOverrides = await apiGetOutstandingPaymentOverrides(params, token, dispatch, rejectWithValue);

			//If there are any missed regular collections append them to the list of Outstanding Payments.
			var index = 1;
			if(missedRegularCollections != null && missedRegularCollections.MissedRegularCollectionsList != null && missedRegularCollections.MissedRegularCollectionsList.length !== 0)
			{
				for(const missedRegCol of missedRegularCollections.MissedRegularCollectionsList)
				{
					const payment = {
						TransactionId: index, //Index number used as a mock transaction ID otherwise the transactions would not be selectable when making payments.
						RegularCollectionDate: missedRegCol.RegularCollectionDate,
						Amount: missedRegCol.RegularCollectionAmount,
						CalculatedCollectionDetails: missedRegCol.CalculatedCollectionDetails, //Missed Regular Collection calculated via Premium Calculation per transaction per benefit need to be saved post payment.
						PaymentOverrideId: null
					}
					outstandingPaymentsResponse.Payments.push(payment);
					index++;
				}
			}

			//If there are any outstanding payment overrides then append them to the list of Outstanding Payments as well.
			if(outstandingPaymentOverrides != null && outstandingPaymentOverrides.OutstandingPaymentOverridesList != null && outstandingPaymentOverrides.OutstandingPaymentOverridesList.length !== 0)
			{
				for(const paymentOverride of outstandingPaymentOverrides.OutstandingPaymentOverridesList)
				{
					//If the dishonour transaction is already in the list then we don't need to add the Payment Arrangment as well.
					if(outstandingPaymentsResponse.Payments.some(x => x.TransactionId === paymentOverride.DishonourTransactionId))
					{
						continue;
					}

					const payment = {
						//For Payment Overrides all Transaction ID fields are nullable so depending on what we have may need to use other fields.
						TransactionId: (paymentOverride.TransactionId != null) ? paymentOverride.TransactionId
							: (paymentOverride.QueuedTransactionId != null) ? paymentOverride.QueuedTransactionId 
							: (paymentOverride.DishonourTransactionId != null) ? paymentOverride.DishonourTransactionId 
							: index,
						//Colleciton Date can be null for some Payment Overrides thus use the Override Date instead.
						RegularCollectionDate: (paymentOverride.CollectionDate != null) ? paymentOverride.CollectionDate 
							: paymentOverride.OverrideDate,
						Amount: paymentOverride.OverrideAmount,
						CalculatedCollectionDetails: null,
						PaymentOverrideId: paymentOverride.PaymentOverrideId
					}
					outstandingPaymentsResponse.Payments.push(payment);
					index++;
				}
			}	
		}

		return outstandingPaymentsResponse;
	}
);

// Set the policy status to "Processing Payment"
export const setPolicyStatusPaymentPending = createAction('policy/SET_POLICY_STATUS_PENDING');

// Update the policy status
export const updatePolicyStatus = createAsyncThunk('policy/UPDATE_POLICY_STATUS', 
	async (policyNumber, { getState, rejectWithValue }) => {
		const customerLoginId = getState().auth.customerLoginId;
		const token = getState().auth.bearerToken;
		return apiGetPolicyDetails(policyNumber, customerLoginId, token, rejectWithValue);
	}
);

// Clear the policy data on logout
export const clearPolicies = createAction('policy/CLEAR_POLICIES');
