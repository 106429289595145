// Externals
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

// Helpers for fetching async data
import { apiGetCustomerDetails, apiUpdateCustomerContact } from '../async/contact';

// Get customer contact details
export const getCustomerDetail = createAsyncThunk('contact/GET_CUSTOMER_DETAIL', 
	// TODO: abortController
	async (args, { getState, rejectWithValue }) => {
		const customerLoginId = getState().auth.customerLoginId;
		const token = getState().auth.bearerToken;
		return await apiGetCustomerDetails(customerLoginId, token, rejectWithValue);
	}
);

// Update the contact field from user data
export const updateContactField = createAction('contact/UPDATE_CONTACT_FIELD');

// Perform the async contact update
export const updateCustomerContacts = createAsyncThunk('contact/UPDATE_CUSTOMER_CONTACTS', 
	// TODO: abortController
	async (args, { getState, rejectWithValue }) => {
		const customerLoginId = getState().auth.customerLoginId;
		const customerContacts = getState().contact.pendingUpdate;
		const token = getState().auth.bearerToken;
		return await apiUpdateCustomerContact(customerLoginId, customerContacts, token, rejectWithValue);
	}
);

// Copy the Residential Contact Info to Postal in response to "Same as residential address" checkbox
export const updateContactCopyResidentialToPostal = createAction('contact/UPDATE_CONTACT_COPY_RESIDENTIAL_POSTAL');

// Receive the reformatted Australia Post address (via contact middleware)
export const updateContactFromAusPostFormattedAddress = createAction('contact/UPDATE_CONTACT_FROM_AUSPOST_FORMATTED_ADDRESS');

// Clear the contact data on logout
export const clearContact = createAction('contact/CLEAR_CONTACT');

// Reset the pending contact update fields
export const resetPendingUpdate = createAction('contact/RESET_PENDING_UPDATE');
