export const getProductTypeIcon = (id) => {
	switch(id){
		case 1:
		case 12:
			return 'familylife-icon';
        case 2:
            return 'funeral-icon';
		case 4:
			return 'income-icon';
		default:
			return 'familylife-icon';
	}
}