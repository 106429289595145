import React from 'react';


const Table = ({className, children, headers}) =>{ 
    return(
        <table className={className}>
            { headers &&
            <thead>
                <tr>
                {headers.map((header, index)=> (
                    <th key={index}>{header}</th>
                ))}
                </tr>
            </thead>
            }
            
            <tbody>
                {children}
            </tbody>
        </table>
    )
}

export default Table;