// Externals
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { finalisePayNowTransaction, markPolicyPayNowPending } from '../../store/actions/payment';

// Selectors
import { firstNameSelector } from '../../store/selectors/contact';

// Components
import FlashMessage from '../Widgets/FlashMessage';
import Spinner from '../Widgets/Spinner';

// Styles
import './MakePayment.scss';
import { makePaymentMessageSelector } from '../../store/selectors/brand';

// Finalise the transaction
const MakePaymentFinalise = () => {

	const dispatch = useDispatch();
	const { policyNumber } = useParams();
	const [ searchParams ] = useSearchParams();

	const username = useSelector(firstNameSelector);
	const receiptReference = useSelector(state => state.payment.payNowGateway.ReceiptReference);
	const loading = useSelector(state => state.payment.loading);
	const payNowError = useSelector(state => state.payment.payNowError);
	const makePaymentMessages = useSelector(makePaymentMessageSelector);

	//Azure-88692: During the Extended Dishonour Period when missed regular collections were calculated they need to be saved to tblExtendedDishonourTransactionLog and tblExtendedDishonourTransactionBenefitLog.
	//These calculated missed regular collection details have been added to the overduePayments list thus we need to get this list and pass it to the Pay Now API to save the info.
	const overduePayments = useSelector(state => state.policy.outstandingPayments[policyNumber]);

	// useState to prevent multiple calls to useEffect on re-render
	const [ transactionIdentifier ] = useState(searchParams.get('transactionidentifier'));
	const [ transactionIDs ] = useState(searchParams.get('passthroughparamobject').split(','));

	// Call the API to finalise the payment 
	useEffect(() => {
		if (transactionIdentifier && transactionIDs && receiptReference === null && !payNowError) {
			const params = {
				policyNumber: policyNumber,
				transactionIdentifier: transactionIdentifier,
				transactionIDs: transactionIDs,
				overduePayments: overduePayments //Azure-88692: Pass in overdue payments so we can save the EDP calculated information if there is any.
			};
			dispatch(finalisePayNowTransaction(params));
		}
		if (transactionIdentifier && transactionIDs && receiptReference) {
			dispatch(markPolicyPayNowPending(policyNumber));
		}
	}, [ transactionIdentifier, transactionIDs, overduePayments, receiptReference, dispatch, policyNumber, payNowError ]);

	return (<>

		{loading && !payNowError && <Spinner />}

		{!loading && !payNowError && receiptReference && (<>
			
			<h1>Thank you {username}!</h1>

			<h2>Your payment was processed successfully.</h2>

			<FlashMessage message={'Reference #' + receiptReference} />

			<p>Please allow up to 5 minutes for your policy to be updated.</p>
			<p dangerouslySetInnerHTML={{ __html: makePaymentMessages.makePaymentFinalise }}></p>

			<p><Link to='/'>Back to My Policies</Link></p>

		</>)}

		{payNowError && (<>

			<h1>Sorry {username}.</h1>

			<h2>Your payment was not processed.</h2>
			
			<FlashMessage message='An error occured processing your payment' error={true} />
		
			<p dangerouslySetInnerHTML={{ __html: makePaymentMessages.makePaymentFailure}}></p>

			<p><Link to='/'>Back to My Policies</Link></p>

		</>)}

	</>)
}

export default MakePaymentFinalise;
