
// return an array of user-selected transactionIDs
export const selectedPayNowTransactionIDsSelector = (state) => 
	Object.keys(state.payment.payNowSelectedTransactions);

export const selectedPayNowTransactionIDsTotalAmountSelector = (state) => 
	Object.keys(state.payment.payNowSelectedTransactions).reduce((sum, item) => 
		sum + state.payment.payNowSelectedTransactions[item], 0
	);

// the Pay Now gateway object
export const payNowGatewaySelector = (state) => state.payment.payNowGateway;

// return a list of policy numbers which have a payment pending
export const payNowPoliciesPendingPaymentSelector = state => state.payment.payNowPoliciesPendingPayment;
