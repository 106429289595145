// Externals
import React from 'react';

// Styles
import './Container.scss';

// Header component
const Container = (props) => {
	const { children, className } = props;
	return (
		<div className={'layout-container' + (className ? ' ' + className : '')}>
			{children}
		</div>
	)
};

// Exports
export default Container;
