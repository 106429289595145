// Street Address Lookup

// ENV Config
import config from '../../config';

// Base Host for API calls
const apiHost = config.API_HOST;

const abortControllers = { 
	address: null,
	format: null
};

export const apiLookupAddress = async (address, isResidential, token, rejectWithValue) => {

	if (abortControllers.address) abortControllers.address.abort();
	abortControllers.address = new AbortController();

	const url = apiHost + '/api/v1/Address/searchwithoutpostcode';

	// TODO: sanity check on user-provided data
	const params = '?address=' + address;

	const response = await fetch(url + params, {
		method: 'GET',
		headers: {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json'
		},
		signal: abortControllers.address.signal
	}).catch(error => {
		if (error.name !== 'AbortError') throw rejectWithValue({ httpStatus: 503 });
		else throw rejectWithValue({});
	});

	if (!response.ok) {
		if (response.status) return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return {
			addresses: data,
			isResidential: isResidential
		}
	}

}

export const apiFormatAddress = async (address, isResidential, token, rejectWithValue) => {

	if (abortControllers.format) abortControllers.format.abort();
	abortControllers.format = new AbortController();

	const url = apiHost + '/api/v1/Address/format';

	// TODO: sanity check on user-provided data
	const params = '?address=' + address;

	const response = await fetch(url + params, {
		method: 'GET',
		headers: {
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json'
		},
		signal: abortControllers.format.signal
	}).catch(error => {
		if (error.name !== 'AbortError') throw rejectWithValue({ httpStatus: 503 });
		else throw rejectWithValue({});
	});

	if (!response.ok) {
		if (response.status) return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return {
			address: data,
			isResidential: isResidential
		}
	}
}
