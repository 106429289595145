// Constants & etc

// Poll for session expiry every sessionPoll seconds 
export const sessionPoll = 15000; // 15s

// Poll for processing payments every 
export const pendingPoll = 15000; // 15s

// Used for Update Contacts
export const states = {
	NSW: 'New South Wales',
	ACT: 'Australian Capital Territory',
	VIC: 'Victoria',
	QLD: 'Queensland',
	TAS: 'Tasmania',
	WA: 'Western Australia',
	SA: 'South Australia',
	NT: 'Northern Territory'
};

// The integer maps to Evolve DB
export const countries = {
	1: "Afghanistan",
	2: "Aland Islands",
	3: "Albania",
	4: "Algeria",
	5: "American Samoa",
	6: "Andorra",
	7: "Angola",
	8: "Anguilla",
	9: "Antarctica",
	10: "Antigua and Barbuda",
	11: "Argentina",
	12: "Armenia",
	13: "Australia",
	14: "Aruba",
	15: "Austria",
	16: "Azerbaijan",
	17: "Bahamas",
	18: "Bahrain",
	19: "Bangladesh",
	20: "Barbados",
	21: "Belarus",
	22: "Belgium",
	23: "Belize",
	24: "Benin",
	25: "Bermuda",
	26: "Bhutan",
	27: "Bolivia",
	28: "Bosnia and Herzegovina",
	29: "Botswana",
	30: "Bouvet Island",
	31: "Brazil",
	32: "British Indian Ocean Territory",
	33: "Brunei Darussalam",
	34: "Bulgaria",
	35: "Burkina Faso",
	36: "Burundi",
	37: "Cambodia",
	38: "Cameroon",
	39: "Canada",
	40: "Cape Verde",
	41: "Cayman Islands",
	42: "Central African Republic",
	43: "Chad",
	44: "Chile",
	45: "China",
	46: "Christmas Island",
	47: "Cocos Islands",
	48: "Colombia",
	49: "Comoros",
	50: "Congo",
	51: "Congo",
	52: "Cook Islands",
	53: "Costa Rica",
	54: "Cote DIvoire",
	55: "Croatia",
	56: "Cuba",
	57: "Cyprus",
	58: "Czech Republic",
	59: "Denmark",
	60: "Djibouti",
	61: "Dominica",
	62: "Dominican Republic",
	63: "Ecuador",
	64: "Egypt",
	65: "El Salvador",
	66: "Equatorial Guinea",
	67: "Eritrea",
	68: "Estonia",
	69: "Ethiopia",
	70: "Faeroe Islands",
	71: "Falkland Islands",
	72: "Fiji",
	73: "Finland",
	74: "France",
	75: "French Guiana",
	76: "French Polynesia",
	77: "French Southern Territories",
	78: "Gabon",
	79: "Gambia",
	80: "Georgia",
	81: "Germany",
	82: "Ghana",
	83: "Gibraltar",
	84: "Great Britain",
	85: "Greece",
	86: "Greenland",
	87: "Grenada",
	88: "Guadeloupe",
	89: "Guam",
	90: "Guatemala",
	91: "Guinea",
	92: "Guinea-bissau",
	93: "Guyana",
	94: "Haiti",
	95: "Heard Island and Mcdonald Islands",
	96: "Honduras",
	97: "Hong Kong",
	98: "Hungary",
	99: "Iceland",
	100: "India",
	101: "Indonesia",
	102: "Iran",
	103: "Iraq",
	104: "Ireland",
	105: "Israel",
	106: "Italy",
	107: "Jamaica",
	108: "Japan",
	109: "Jordan",
	110: "Kazakhstan",
	111: "Kenya",
	112: "Kiribati",
	113: "North Korea",
	114: "South Korea",
	115: "Kuwait",
	116: "Kyrgyzstan",
	117: "Lao Peoples Democratic Republic",
	118: "Latvia",
	119: "Lebanon",
	120: "Lesotho",
	121: "Liberia",
	122: "Libya",
	123: "Liechtenstein",
	124: "Lithuania",
	125: "Luxembourg",
	126: "Macao",
	127: "Macedonia",
	128: "Madagascar",
	129: "Malawi",
	130: "Malaysia",
	131: "Maldives",
	132: "Mali",
	133: "Malta",
	134: "Marshall Islands",
	135: "Martinique",
	136: "Mauritania",
	137: "Mauritius",
	138: "Mayotte",
	139: "Mexico",
	140: "Micronesia",
	141: "Moldova",
	142: "Monaco",
	143: "Mongolia",
	144: "Montserrat",
	145: "Morocco",
	146: "Mozambique",
	147: "Myanmar",
	148: "Namibia",
	149: "Nauru",
	150: "Nepal",
	151: "Netherlands",
	152: "Netherlands Antilles",
	153: "New Caledonia",
	154: "New Zealand",
	155: "Nicaragua",
	156: "Niger",
	157: "Nigeria",
	158: "Niue",
	159: "Norfolk Island",
	160: "Northern Mariana Islands",
	161: "Norway",
	162: "Oman",
	163: "Pakistan",
	164: "Palau",
	165: "Palestinian Territories",
	166: "Panama",
	167: "Papua New Guinea",
	168: "Paraguay",
	169: "Peru",
	170: "Philippines",
	171: "Pitcairn",
	172: "Poland",
	173: "Portugal",
	174: "Puerto Rico",
	175: "Qatar",
	176: "Reunion",
	177: "Romania",
	178: "Russian Federation",
	179: "Rwanda",
	180: "Saint Helena",
	181: "Saint Kitts and Nevis",
	182: "Saint Lucia",
	183: "Saint Pierre and Miquelon",
	184: "Saint Vincent and the Grenadines",
	185: "Samoa",
	186: "San Marino",
	187: "Sao Tome and Principe",
	188: "Saudi Arabia",
	189: "Senegal",
	190: "Serbia and Montenegro",
	191: "Seychelles",
	192: "Sierra Leone",
	193: "Singapore",
	194: "Slovakia",
	195: "Slovenia",
	196: "Solomon Islands",
	197: "Somalia",
	198: "South Africa",
	199: "South Georgia and the South Sandwich Islands",
	200: "Spain",
	201: "Sri Lanka",
	202: "Sudan",
	203: "Suriname",
	204: "Svalbard and Jan Mayen",
	205: "Swaziland",
	206: "Sweden",
	207: "Switzerland",
	208: "Syrian Arab Republic",
	209: "Taiwan",
	210: "Tajikistan",
	211: "Tanzania",
	212: "Thailand",
	213: "Timor-Leste",
	214: "Togo",
	215: "Tokelau",
	216: "Tonga",
	217: "Trinidad and Tobago",
	218: "Tunisia",
	219: "Turkey",
	220: "Turkmenistan",
	221: "Turks and Caicos Islands",
	222: "Tuvalu",
	223: "Uganda",
	224: "Ukraine",
	225: "United Arab Emirates",
	227: "United States",
	228: "United States Minor Outlying Islands",
	229: "Uruguay",
	230: "Uzbekistan",
	231: "Vanuatu",
	232: "Vatican City",
	233: "Venezuela",
	234: "Viet Nam",
	235: "Virgin Islands British",
	236: "Virgin Islands US",
	237: "Wallis and Futuna",
	238: "Western Sahara",
	239: "Yemen",
	240: "Zambia",
	241: "Zimbabwe"
};
