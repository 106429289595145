// Strings
import { SeniorsBrandHeadings, SeniorsBrandMessages, SeniorsBrandPhone } from './AustralianSeniors/strings/BrandMessages';
import { RealBrandHeadings, RealBrandMessages, RealBrandPhone } from './RealInsurance/strings/BrandMessages';

// Logos
import RealLogo from './RealInsurance/static/RealInsurance.svg';
import SeniorsLogo from './AustralianSeniors/static/logo-auseniors.svg';

const setBrandHeadings = (brand) => {
    switch(brand){
        case 'real': 
            return RealBrandHeadings;
        case 'seniors': 
            return SeniorsBrandHeadings;
        default:
            return RealBrandHeadings;
    }
}

const setBrandMessages = (brand) => {
    switch(brand){
        case 'real': 
            return RealBrandMessages;
        case 'seniors': 
            return SeniorsBrandMessages;
        default:
            return RealBrandMessages;
    }
}

const setBrandPhone = (brand) => {
    switch(brand){
        case 'real': 
            return RealBrandPhone;
        case 'seniors': 
            return SeniorsBrandPhone;
        default: 
            return RealBrandPhone;
    }
}

const setBrandLogo = (brand) => {
    switch(brand){
        case 'real':
            return RealLogo;
        case 'seniors':
            return SeniorsLogo;
        default:
            return RealLogo;
    }
}

const createBrandState = (brand) => {
    return {
		brandHeadings: setBrandHeadings(brand),
        brandMessages: setBrandMessages(brand),
        brandPhone: setBrandPhone(brand),
        brandLogo: setBrandLogo(brand)
    }
}

const customisations = {
    real: createBrandState('real'),
    seniors: createBrandState('seniors')
}

export default customisations;
