// Externals
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { customerSessionCheck } from './store/actions/auth';

// Selectors
import { loggedInSelector } from './store/selectors/auth';

// Constants
import { sessionPoll } from './store/constants';

// Hooks
import useInterval from './util/useInterval';

// Auth Container
const Auth = (props) => {

	const loggedIn = useSelector(loggedInSelector);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	// Redirect to /login if no session exists
	useEffect(() => {
		if (!loggedIn) {
			navigate('/login')
		}
	}, [ loggedIn, navigate, dispatch ]);
	
	// Check whether the session has expired
	useInterval(() => {
		dispatch(customerSessionCheck());
	}, loggedIn ? sessionPoll : null);

	// This component is a wrapper
	return props.children;
}

// Exports
export default Auth;
