export const SeniorsBrandHeadings = {
	myAccountLogin: 'My Account Login',
	myInsurancePolicies: 'My Insurance Policies',
	policyOverview: 'Policy Overview',
	policySummary: 'Policy Summary',
	paymentDetails: 'Payment Details',
	livesInsured: 'Lives Insured Details',
	beneficiaryDetails: 'Beneficiary Details',
	updateDetails: 'Update Details',
	myPaymentDetails: 'My Payment Details',
	editPaymentDetails: 'Edit Payment Details',
	myContactDetails: 'My Contact Details',
	customerContactDetails: 'Customer Contact Details',
	editContactDetails: 'Edit Contact Details',
	makePayment: 'Make a Payment',
	myDocuments: 'Policy Documents'
};

export const SeniorsBrandMessages = {
    changeToPolicyCTA: 'You can update your contact and payment details online. If you would like to make additional changes to your policy, please contact us on <a href="tel:1800004005">1800 004 005</a>, ' +
    'between 8am and 8pm (AEST) Monday to Friday, and we’ll be happy to help.',
    chequeMethodChangeCTA: 'Due to your current payment method, you will need to contact us on <a href="tel:1800004005">1800 004 005</a>, ' +
    'between 8am and 8pm (AEST) Monday to Friday, to make changes to your payments details for this policy.',
    ctaContactDetails: 'Update email, postal address and phone number.',
    ctaPaymentDetails: 'Update payment card or bank account details.',
    ctaMakeAPayment: 'Pay overdue payments by credit card.',
    ctaWereHereToHelpTitle: 'Contact Us',
    ctaWereHereToHelpText: 'Can’t find what you are looking for? Please contact us on <a href="tel:1800004005">1800 004 005</a>, between 8am and 8pm (AEST) Monday to Friday, and we’ll be happy to help.',
    loginFailureMessage: 'Your login details are incorrect. Please try again or call us on <a href="tel:1800004005">1800 004 005</a>, between 8am and 8pm (AEST) Monday to Friday.',
    mobileHelpText: 'This is the mobile number we have on file for your policy',
    dobHelpText: 'This is the date of birth of the policyowner as listed on your policy schedule',
    editContactDetailsTerms: 'Any changes made to your contact details will be applied across all of your current life insurance policies with Australian Seniors. ' +
    'If you have any other policies with us, such as Car or Home Insurance, your contact details will need to be updated separately. Please visit the ' +
    '<a href="https://seniors.com.au/" target="_blank">Australian Seniors website</a> for more information on how to manage your policy.',
    contactNameChangeHelpText: 'To update the contact name on your policy please call us on <a href="tel:1800004005">1800 004 005</a>, between 8am and 8pm (AEST) Monday to Friday.',
    contactUpdateFailureMessage: 'We were unable to update your contact details. Please try again or call us on <a href="tel:1800004005">1800 004 005</a>, between 8am and 8pm (AEST) Monday to Friday.',
    paymentUpdateFailureMessage: 'We were unable to update your payment details. Please try again or call us on <a href="tel:1800004005">1800 004 005</a>, between 8am and 8pm (AEST) Monday to Friday.',
    makePaymentFinaliseMessage: 'If you have any questions regarding your policy please call us on <a href="tel:1800004005">1800 004 005</a>, Monday to Friday between 8am and 8pm (AEST), and we\'ll be happy to help.',
    makePaymentFailureMessage: 'Please try again or contact our support team on <a href="tel:1800004005">1800 004 005</a>, Monday to Friday between 8am and 8pm (AEST), and we\'ll be happy to help.',
    headerText: 'Life & Funeral Insurance',
    brandLogoAlt: 'Australian Seniors',
    footerLinkLabels: ['Privacy Collection Notice', 'Terms of Use', 'Make a Claim', 'Contact us'],
    footerLinkDestinations: [
        'https://www.seniors.com.au/privacy-collection-notice',
        'https://www.seniors.com.au/terms-of-use',
        'https://www.seniors.com.au/how-to-claim',
        'https://www.seniors.com.au/contact'
    ],
    footerTextHtml: '<p>Australian Seniors is a trading name of Greenstone Financial Services Pty Ltd <span class=\'no-wrap\'>ABN 53 128 692 884, </span><span class=\'no-wrap\'>AFSL 343079</span>.' + 
    'The Insurer for all life insurance products is Hannover Life Re of Australasia Ltd <span class=\'no-wrap\'>ABN 37 062 395 484, AFSL 530811</span>.</p>' +
    '<p>Information is general only and does not consider your individual objectives or financial situation. Terms and conditions apply. Please read the relevant Product Disclosure Statement and Target Market Determination available at ' +
    '<a href=\'https://seniors.com.au\' target=\'_blank\'>seniors.com.au</a> to ensure these products are right for you.</p>',
    beneficiariesFormLink: 'https://www.seniors.com.au/documents/australian-seniors-beneficiary-nomination-form.pdf'
}

export const SeniorsBrandPhone = '1800 004 005';
