import React from "react";
import { useSelector } from 'react-redux';

// Selectors
import { headingSelector, beneficiariesFormLinkSelector } from '../../../store/selectors/brand';

// Utils
import { formatDate } from "../../../util/formats";

// Components
import Table from "../../Layout/Table";
import TableRow from "../../Layout/TableRow";

const BeneficiaryDetails = ({ policy }) => {
    const beneficiaryHeaders = ['Beneficiary Name', 'Beneficiary Date of Birth', 'Proportion of Benefit %'];
    const sectionHeading = useSelector(state => headingSelector(state, 'beneficiaryDetails'));
    const beneficiariesFormLink = useSelector(beneficiariesFormLinkSelector);

    return (
        <>
            <div className='payment-detail-header detail-heading'>
                <h3>{sectionHeading}</h3>
                <span className='edit-link-container'>
                    <a className='edit-link' href={beneficiariesFormLink} target="_blank" rel="noopener noreferrer">Beneficiary Form</a>
                </span>
            </div>
            {policy.Beneficiaries.length > 0 &&
                <>
                    <Table key={0} className={'policy-detail-table'} headers={beneficiaryHeaders}>
                        {policy.Beneficiaries.map((ben, index) => (
                            <TableRow key={index} valueClassName='gfs-app-tl-mask' values={[ben.FirstName + ' ' + ben.LastName, formatDate(ben.BirthDate), ben.Percentage + '%']} />
                        ))}
                    </Table>


                    { policy.Beneficiaries.map((ben, i) => {
                        const values = [ben.FirstName + ' ' + ben.LastName, formatDate(ben.BirthDate), ben.Percentage + '%'];
                        return <Table key={'m-' + i} className={'policy-detail-table-mobile'}>
                            {beneficiaryHeaders.map((header, index) => (
                                <TableRow key={index} valueClassName='gfs-app-tl-mask' heading={header} value={values[index]} />
                            ))}
                        </Table>
                    })}
                </>
            }

            {policy.Beneficiaries.length === 0 && <>                
                <Table key={0} className={'policy-detail-table show-on-mobile'} headers={['Beneficiaries']}>
                    <TableRow key={0} values={['Currently there is no beneficiary listed on this policy']} />
                </Table>
            </>}

        </>
    )
}

export default BeneficiaryDetails;
