// Externals
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Selectors
import { validPoliciesSelector } from '../store/selectors/policy';
import { policyDocumentsSelector } from '../store/selectors/documents';
import { headingSelector } from '../store/selectors/brand';

// Components
import Container from '../components/Layout/Container';
import PolicyDetail from '../components/Policy/Detail';
import PolicyHeader from '../components/Widgets/PolicyHeader';
import FlashMessage from '../components/Widgets/FlashMessage';
import OverdueBanner from '../components/Widgets/OverdueBanner';
import DocumentsButton from '../components/Documents/Button';

// Pages
import Error404 from '../pages/Error404';

// Policies container
const Policies = () => {
	const { policyNumber } = useParams();
	const validPolicies = useSelector(validPoliciesSelector);
	const policyDocuments = useSelector(state => policyDocumentsSelector(state, policyNumber));

	const pageHeading = useSelector(state => headingSelector(state, 'myInsurancePolicies'));

	const [ flashMessage, setFlashmessage ] = useState(false);
	useEffect(() => {
		// show success flash message
		const p = new URLSearchParams(document.location.search);
		if (p.get('success')) setFlashmessage(true);
	}, []);

	if (!validPolicies.includes(policyNumber)) {
		return <Error404 />;
	}
	else return (
		<>
			<PolicyHeader />

			<OverdueBanner />

			<Container>
				<div className='page-view-policy'>

					{ policyDocuments && <DocumentsButton policyNumber={policyNumber} /> }

					<h1>{ pageHeading }</h1>

					{ flashMessage && <FlashMessage message={'Your payment details have been updated'} /> }

					<PolicyDetail policyNumber={policyNumber} />

				</div>
			</Container>
		</>
	);
};

// Exports
export default Policies;
