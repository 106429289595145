// Externals
import React from 'react';
import { useSelector } from 'react-redux';

// Selectors
import { policySummariesSelector } from '../store/selectors/policy';
import { headingSelector } from '../store/selectors/brand';

// Components
import OverdueBanner from '../components/Widgets/OverdueBanner';
import Container from '../components/Layout/Container';
import PolicySummary from '../components/Policy/Summary';

// Styles
import './MyPolicies.scss';

// Dashboard
const MyPolicies = () => {

	const policies = useSelector(policySummariesSelector);
	const pageHeading = useSelector(state => headingSelector(state, 'myInsurancePolicies')); 

	return (<>

		<OverdueBanner />

		<Container>

			<div className='page page-dashboard'>

				<h1>{pageHeading}</h1>

				<div className='policy-summaries'>
					{policies.map((policy, index) => (
						<PolicySummary key={index} {...policy} />
					))}
				</div>
			</div>

		</Container>
	</>);
};

export default MyPolicies;
