// Policy Lookup

// ENV Config
import config from '../../config';

// Base Host for API calls
const apiHost = config.API_HOST;

export const apiGetPolicyDocumentList = async (PolicyNumber, ClientPortalLoginId, token, rejectWithValue) => {
	const url = apiHost + '/api/v1/Document/documentlist';

	const params = '?PolicyNumber=' + PolicyNumber
		+ '&ClientPortalLoginId=' + ClientPortalLoginId
		+ '&Dms=0';

	const response = await fetch(url + params, {
		method: 'GET',
		headers: { 
			Authorization: 'Bearer ' + token,
			'Content-Type': 'application/json'
		}
	}).catch(() => {
		throw rejectWithValue({ httpStatus: 503 });
	});

	if (!response.ok) {
		return rejectWithValue({ httpStatus: response.status });
	}
	else {
		const data = await response.json();
		return data;
	}
}

