// Externals
import React from 'react';

// Components 
import Spinner from './Spinner';

// Styles
import './SpinnerOverlay.scss';

// Spinner Overlay component
const SpinnerOverlay = (props) => {
	const { message } = props;
	return (
		<div className='spinner-overlay'>
			<div className='loading-modal'>
				<Spinner />
				<h2 className='loading-heading'>Just a moment</h2>
				{ message && <p className='loading-text'>{message}</p>}
			</div>
		</div>
	);
};

// Exports
export default SpinnerOverlay;
