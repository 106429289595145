// Externals
import React from 'react';
import { useSelector } from 'react-redux';

// Selectors
import { headingSelector } from '../store/selectors/brand';

// Components
import Container from '../components/Layout/Container';

// Forms
import FormContactDetails from '../components/Forms/ContactDetails';

// Update Contact Details
const ContactDetailsUpdate = () => {
	const pageHeading = useSelector(state => headingSelector(state, 'myContactDetails'));
	const pageSubHeading = useSelector(state => headingSelector(state, 'editContactDetails'));

	return (
		<Container>
			<div className='page-container contacts-container'>
				<h1>{pageHeading}</h1>
				<h2>{pageSubHeading}</h2>

				<FormContactDetails />

			</div>
		</Container>
	);
}

// Exports
export default ContactDetailsUpdate;
