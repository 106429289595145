export const validateField = (ref, fieldRef) => {
    if(ref.current && !ref.current.checkValidity()){
        markInvalid(fieldRef);
        return false;
    } else if(ref.current && ref.current.checkValidity()){
        clearInvalid(fieldRef)
        return true;
    }

    return false;
}

export const validateExpiry = (mm, yyyy) => {
    const date = new Date();
    date.setFullYear(yyyy, mm - 1, 1);
    const today = new Date();
    return today < date;
}

export const markInvalid = (ref) => {
    if(ref.current && !ref.current.className.includes(' invalid')){
        ref.current.className = ref.current.className + ' invalid';
    }
}

export const clearInvalid = (ref) => {
    if(ref.current){
        ref.current.className = ref.current.className.replace(' invalid', '');
    }
}

export const markValid = (ref) => {
    if(ref.current && !ref.current.className.includes(' valid')){
        ref.current.className = ref.current.className + ' valid';
    }
}

export const clearValid = (ref) => {
    if(ref.current){
        ref.current.className = ref.current.className.replace(' valid', '');
    }
}

export const getNumericCharKeyCode = (code) => {
    return (code >= 96 && code <= 105) ? code - 48 : code;
}

export const isNumericKeyCode = (e) => {
    return (e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105);
}

export const preventNonNumericInput = (e) => {
    if(!(((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) || //0 TO 9
				e.which === 8 ||  e.which === 46 || // Backspace | Delete
				e.which === 37 || e.which === 39 || // LArrow | RArrow
				e.which === 9 || e.which === 35 || e.which === 36 || // Tab | End | Home
                ((e.ctrlKey || e.metaKey) && (e.code === 'KeyZ' || e.code === 'KeyA' || e.code === 'KeyX' || e.code === 'KeyC' || e.code === 'KeyV')))){ //Undo | SelectAll | Cut | Copy | Paste 
					e.preventDefault();
                    return false;
    } 

    return true;

}

export const handleNumericOnlyInput = (e) => {
    const isBackspace = e.which === 8;
    const isDelete = e.which === 46;
    const isNumber = isNumericKeyCode(e);
    const caretPos = e.target.selectionStart
    const selection = getSelectionText();
    var value = e.target.value;
    if(isBackspace && caretPos > 0){
        value = caretPos < value.length ? value.substring(0, caretPos - 1) + value.substring(caretPos, value.length) : value.substring(0, caretPos - 1);
    } else if(isDelete && caretPos <= value.length - 1){
        value = value.substring(0, caretPos) + value.substring(caretPos + 1, value.length);
    } else if(isNumber) {
        const keycode = getNumericCharKeyCode(e.which);
        value = selection.length > 0 ? value.replace(selection, String.fromCharCode(keycode)) : value.substring(0, caretPos) + String.fromCharCode(keycode) + value.substring(caretPos, value.length);
    }

    return value;
}

export const dayAndMonthValidation = (day, month, year) => {
    if(day !== null && (day < 1 || day > 31)){
        return false;
    } 

    if(month !== null && (month < 1 || month > 12)){
        return false
    }
   
    if(month !== null && !isNaN(month) && year !== null && !isNaN(year)){
        
        if(!ageValidation(year)){
            return false;
        }

        if(month === 0 || month > 12){
            return false
        }

        switch (month) {
            case 2 : //Febrary
                if (year % 4) {
                    return day <= 28; //common year
                }
                if (year % 100) {
                    return day <= 29; //  leap year
                }
                
                if (year % 400) {
                    return day <= 28; //common year
                }
                return day <= 29; //  leap year
            case 9 : case 4 : case 6 : case 11 :
                return day <= 30;
            default :
                return day <=  31
        }
    }

    return null;
} 

export const ageValidation = (year) => {
    const date = new Date();
    const dob = new Date();
    dob.setFullYear(year);
    const age = date.getFullYear() - dob.getFullYear();
    if(age < 18 || age > 99){
        return false;
    }

    return true;
}

export const getSelectionText = () => {
    var text = "";
    if (window.getSelection) {
        text = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== "Control") {
        text = document.selection.createRange().text;
    }
    return text;
}