// Externals
import React from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

// Hooks
import useInterval from '../../util/useInterval';

// Selectors
import { overduePaymentsBannerSelector } from '../../store/selectors/policy';
import { payNowPoliciesPendingPaymentSelector } from '../../store/selectors/payment';

// Actions
import { getPolicyOutstandingPayments } from '../../store/actions/policy';

// Components
import Container from '../Layout/Container';

// Utility
import { formatMoneyWithDecimal } from '../../util/formats';

// Styles
import './OverdueBanner.scss';

import { pendingPoll } from '../../store/constants';

// Dashboard
const OverdueBanner = () => {

	const overduePayments = useSelector(overduePaymentsBannerSelector);
	const pendingPayments = useSelector(payNowPoliciesPendingPaymentSelector);
	//Azure-88692: If policy is within the Extended Dishonour Period then all outstanding payments must be paid to bring the policy back in force.
	//EDP Status ID 1 = Active (i.e. Policy is within the Extended Dishonour Period)
	const allPolicyDetails = useSelector(state => state.policy.policyDetails);
	const allPolicyKeys = useSelector(state => Object.keys(state.policy.policyDetails));
	var isAnyPolicyInEdp = false;
	if(allPolicyKeys.length > 0)
	{
		for(const policyKey of allPolicyKeys)
		{
			if(allPolicyDetails[policyKey].PolicySummary.ExtendedDishonourPeriodStatusId === 1 || allPolicyDetails[policyKey].PolicySummary.ExtendedDishonourPeriodStatusId === 2)
			{
				isAnyPolicyInEdp = true;
				break;
			}
		}	
	}
	
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleClick = (policyNumber) => {
		navigate('/payment/' + policyNumber)
	};

	// if there are pending payments, keep checking for completion
	useInterval(() => {
		pendingPayments.forEach(policyNumber => {
			dispatch(getPolicyOutstandingPayments(policyNumber));
		})
	}, pendingPayments.length > 0 ? pendingPoll : null);

	// Render
	return overduePayments && (
			<div className='overdue-banner'>
			<Container>
				{ isAnyPolicyInEdp && (
					<div className='edp-banner'>
						<div className='banner'>
							<div className='message'>
								<table>
									<tbody>
										<tr>
											<td><div className='notification-bell' /></td>
											<td><span className='message-content'>Your premiums remain overdue, and we would like to provide you with a final opportunity to keep your insurance in place.  To keep your insurance in place, you are required to pay the total amount of premiums overdue.</span></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				)}
			</Container>
			<Container>
				{ overduePayments.map((payment, index) => (
					<div className='banner' key={index}>
						<div className='message'>
							{ !payment.hasPending && (<>
								<div className='notification-bell' />
								<span className='message-content'>
									<b>
										{payment.overdueCount}
										{' '}
										{payment.overdueCount > 1 ? 'payments' : 'payment'}
										{' '}
										({ formatMoneyWithDecimal(payment.totalDue) })
									</b>
									{' '}
									{payment.overdueCount > 1 ? 'are' : 'is'}
									{' '}
									overdue for your
									{' '}
									<b>{payment.policyName}</b>
									{' '}
									policy
								</span>
							</>)}
							{ payment.hasPending && (<>
								<div className='notification-bell' />
								<span className='message-content'>
									<b>Processing</b> overdue payment for your
									{' '}
									<b>{payment.policyName}</b>
									{' '}
									policy
								</span>
							</>)}
						</div>
						{ !payment.hasPending && (
							<button onClick={() => handleClick(payment.policyNumber)} className='btn btn-secondary btn-rounded'>
								<span>Pay Now</span> 
								<span className='chevron-r'></span>
							</button>
						)}
					</div>
				))}
			</Container>
		</div>
	);

};

// Exports
export default OverdueBanner;
