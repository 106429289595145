import React from 'react';
import { useSelector } from 'react-redux';

// Selectors
import { headingSelector } from '../../../store/selectors/brand';

// Components
import Table from '../../Layout/Table';
import TableRow from '../../Layout/TableRow';
import SpecialProvisionsModal from './SpecialProvisionsModal';

// Utils
import { formatDate, formatMoney } from '../../../util/formats';

const LifeInsureds = ({policy}) => {
    const ipBenefitHeaders = ['Benefit Name', 'Benefit Start Date', 'Benefit Amount', 'Waiting Period', 'Benefit Period', 'Special Provisions'];
    const lifeBenefitHeaders = ['Benefit Name', 'Benefit Start Date', 'Benefit Amount', 'Special Provisions'];
    const funeralBenefitHeaders = ['Benefit Name', 'Benefit Start Date', 'Benefit Amount'];
    const livesInsured = [...policy.LifeInsured];
	const sectionHeading = useSelector(state => headingSelector(state, 'livesInsured'));

    return (
        <>
            <div className='detail-heading'><h3>{sectionHeading}</h3></div>
            { policy.Product.ProductType.ProductTypeId === 1 && livesInsured.map( (lifeInsured, key) => ( //Life
                <div className='life-insured' key={key}>
                    <Table key={0} className={'life-insured-detail'}>
                        <tr className={'life-insured-detail-name'} key={0}>
                            <td key={0}>Life Insured Name</td>
                            <td key={1} className='gfs-app-tl-mask'><div>{lifeInsured.FirstName + ' ' + lifeInsured.LastName}</div></td>
                        </tr> 
                        <TableRow key={1} valueClassName='gfs-app-tl-mask' values={['Date of Birth', formatDate(lifeInsured.BirthDate)]}/>
                    </Table>

                    <Table key={1} className={'policy-detail-table'} headers={lifeBenefitHeaders}>
                        { lifeInsured.BenefitDetails.map((details, index) => {
                            const hasExclusions = details.BenefitExclusions.length > 0;
                            return <TableRow key={index} labels={lifeBenefitHeaders} values={[details.BenefitDescription, formatDate(details.RiskStartDate), formatMoney(details.CpiSumInsured), !hasExclusions ? 'N/A' : <SpecialProvisionsModal benefit={details}/>]}/>
}                       )}
                    </Table>
                    { lifeInsured.BenefitDetails.map((details, index) => {
                        const hasExclusions = details.BenefitExclusions.length > 0;
                        const values = [details.BenefitDescription, formatDate(details.RiskStartDate), formatMoney(details.CpiSumInsured), !hasExclusions ? 'N/A' : <SpecialProvisionsModal benefit={details}/>];
                        return <Table key={2 + index} className={'policy-detail-table-mobile'}>
                            { lifeBenefitHeaders.map((header, index) => (
                                <TableRow key={index} heading={header} value={values[index]} />
                            ))}                           
                        </Table>
                    })}
                </div>
            )) }
            
            { (policy.Product.ProductType.ProductTypeId === 2 //Funeral
            || policy.Product.ProductType.ProductTypeId === 12 
            || policy.Product.ProductType.ProductTypeId === 4) //Income Protection
            && livesInsured.map( (lifeInsured, key) => ( 
                <div className='life-insured' key={key}>
                    <Table key={1} className={'life-insured-detail'}>
                        <tr className={'life-insured-detail-name'} key={0}>
                            <td key={0}>Life Insured Name</td>
                            <td key={1} className='gfs-app-tl-mask'><div>{lifeInsured.FirstName + ' ' + lifeInsured.LastName}</div></td>
                        </tr> 
                        <TableRow key={1} valueClassName='gfs-app-tl-mask' heading='Date of Birth' value={formatDate(lifeInsured.BirthDate)}/>
                        { policy.LifeInsured[0].BenefitDetails[0].ExpectedBenefitExpiryDate && policy.Product.ProductType.ProductTypeId === 12 &&
                            <TableRow key={2} values={['Benefit Expiry Date', formatDate(policy.LifeInsured[0].BenefitDetails[0].ExpectedBenefitExpiryDate)]}/>
                        }
                    </Table>

                   
                        {(policy.Product.ProductType.ProductTypeId === 2 //Funeral
                            || policy.Product.ProductType.ProductTypeId === 12) && //Term-Life
                            <>
                            <Table key={2} className="policy-detail-table" headers={funeralBenefitHeaders}>
                                { lifeInsured.BenefitDetails.map((details, index) => (
                                        <TableRow key={index} values={[details.BenefitDescription, formatDate(details.RiskStartDate), formatMoney(details.CpiSumInsured)]}/>
                                ))}
                            </Table>

                            <div key={3}>
                                { lifeInsured.BenefitDetails.map((details, index) => {
                                    const values = [details.BenefitDescription, formatDate(details.RiskStartDate), formatMoney(details.CpiSumInsured)]

                                    return <Table key={index} className="policy-detail-table-mobile">
                                        {funeralBenefitHeaders.map((header, index) => (
                                            <TableRow key={index} heading={header} value={values[index]}/>
                                        ))}
                                    </Table>
                                })}
                            </div>
                            </>
                        }
                    {policy.Product.ProductType.ProductTypeId === 4 && //Income Protection
                         <>
                            <Table key={2} className={'policy-detail-table income-protection'} headers={ipBenefitHeaders}>
                                { lifeInsured.BenefitDetails.map((details, index) => {
                                    const hasExclusions = details.BenefitExclusions.length > 0;
                                    return <TableRow key={index} labels={ipBenefitHeaders} values={[details.BenefitDescription, formatDate(details.RiskStartDate), formatMoney(details.CpiSumInsured), details.WaitingPeriodInDays + ' Days', details.BenefitPeriodInMonths/12 + ' Years', !hasExclusions ? 'N/A' : <SpecialProvisionsModal benefit={details}/>]}/>
                                })}
                            </Table>
                            <div key={3}>
                                { lifeInsured.BenefitDetails.map((details, index) => {
                                    const hasExclusions = details.BenefitExclusions.length > 0;
                                    const values = [details.BenefitDescription, formatDate(details.RiskStartDate), formatMoney(details.CpiSumInsured), details.WaitingPeriodInDays + ' Days', details.BenefitPeriodInMonths/12 + ' Years', !hasExclusions ? 'N/A' : <SpecialProvisionsModal benefit={details}/>];
                                    return  <Table key={index} className={'policy-detail-table-mobile'}>
                                        { ipBenefitHeaders.map((header, index) => (
                                            <TableRow key={index} heading={header} value={values[index]}/>
                                        )) }
                                    </Table>
                                }) }
                            </div>
                        </>   
                    }
                </div>
                ))
            }

        </>  
    )
}

export default LifeInsureds;
