// Externals
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Selectors
import { documentTypeSelector } from '../../store/selectors/documents';

// Components
import SpinnerOverlay from '../Widgets/SpinnerOverlay';
import ViewEmail from './ViewEmail';

// Styles
import './View.scss';

// ENV Config
import config from '../../config';

// Base Host for API calls
const apiHost = config.API_HOST;

// View Document
const DocumentView = () => {
	const { policyNumber, documentNumber } = useParams();
	const bearerToken = useSelector(state => state.auth.bearerToken);
	const documentType = useSelector(state => documentTypeSelector(state, policyNumber, documentNumber));

	const documentUrl = apiHost + '/api/v1/Document/documentstream?Id=' + documentNumber;
	const [ document, setDocument ] = useState(null);

	useEffect(() => {
		if (documentNumber && document === null) {
			fetch(documentUrl, {
				headers: {
					Authorization: 'Bearer ' + bearerToken
				}
			})
			.then(response => response.text())
			.then(base64 => {
				base64 = base64.replace(/^"(.+)"$/, '$1').replace(/(\r\n|\n|\r)/gm, '');
				if (documentType === 'pdf') {
					setDocument(base64);
				}
				if (documentType === 'eml') {
					// TODO: remove debug
					// console.log(window.atob(base64));
					setDocument(window.atob(base64));
				}
			});
		}
	}, [ documentNumber, documentUrl, document, documentType, bearerToken ]);
	
	if (documentType === null) return <p>Document not found</p>;
	if (!document) return <SpinnerOverlay message='Loading your document...' />;
	if (document && documentType === 'pdf') return (
		<iframe 
			className='document-view-pdf' 
			title='document' 
			src={'data:application/pdf;base64,' + document} 
		/>
	);
	else if (document && documentType === 'eml') return (
		<ViewEmail>{document}</ViewEmail>
	)
};

// Export
export default DocumentView;
