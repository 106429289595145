// Externals
import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Selectors
import { validPoliciesSelector } from '../store/selectors/policy';
import { headingSelector } from '../store/selectors/brand';

// Components
import Container from '../components/Layout/Container';
import PolicyHeader from '../components/Widgets/PolicyHeader';
import UpdatePaymentForm from '../components/Forms/UpdatePaymentForm';

// Pages
import Error404 from '../pages/Error404';

// Styles
import './UpdatePayment.scss';

// Payment container
const Payment = () => {

	const { policyNumber } = useParams();
	const validPolicies = useSelector(validPoliciesSelector);
	const pageHeading = useSelector(state => headingSelector(state, 'myPaymentDetails'));
	const pageSubHeading = useSelector(state => headingSelector(state, 'editPaymentDetails'));

	if (!validPolicies.includes(policyNumber)) {
		return <Error404 />;
	}
	else return (
		<>
			<PolicyHeader />
			<Container>
			<div className='page-update-payment'>
				<h1>{pageHeading}</h1>
				<h2>{pageSubHeading}</h2>
				
				<UpdatePaymentForm />
					
				</div>
			</Container>
		</>
	);
};

// Exports
export default Payment;
