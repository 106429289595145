// Externals
import { createSlice } from '@reduxjs/toolkit';

// Internals
import config from '../../config';
import customisations from '../../brands/customisation';

const initialState = { 
    brandId: config.BRAND_ID,
    brandCountry: config.DEFAULT_COUNTRY_ID,
    ...customisations[config.BRAND]
}

const brand = createSlice({
	name: 'brand',
	initialState,
	reducers: {},
});


export default brand.reducer;
