// Externals
import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import PostalMime from 'postal-mime';

// Styles
import './ViewEmail.scss';

// View Email Component
const Email = (props) => {

	const emailMessage = props.children;
	const [ parsedEmail, setParsedEmail ] = useState(null);
	const ref = useRef();
	const attachmentsRef = useRef();

	useEffect(() => {
		if (emailMessage.length > 0 && parsedEmail === null) {
			const parser = new PostalMime();
			parser.parse(emailMessage).then(email => {
				setParsedEmail(email);
			})
		}
	}, [ parsedEmail, emailMessage ]);

	// inject inline images
	useLayoutEffect(() => {
		if (parsedEmail && parsedEmail.attachments.length > 0 && ref.current.children.length > 0) {
			ref.current.querySelectorAll('img').forEach(img => {
				if (/^http:\/\/.+\/documents\/[0-9]+/.test(img.src)){
                    // replace with inline attachment
                    const attachmentId = img.src.replace(/^http:\/\/.+\/documents\/[0-9]+\/(.+)$/, '<$1>');
                    const attachment = parsedEmail.attachments.find(attachment => attachment.contentId && attachment.contentId === attachmentId);
                    if (attachment) {
                        img.src = URL.createObjectURL(new Blob([attachment.content], { type: attachment.mimeType }))
                    }
                }
            });
		}
	}, [ parsedEmail, ref ]);

	// attachments
	useEffect(() => {
		if (parsedEmail && parsedEmail.html.length > 0 && attachmentsRef.current && attachmentsRef.current.children.length === 0) {
			parsedEmail.attachments.forEach(attachment => {
				if (attachment.mimeType === 'application/pdf') {
					const attachmentLink = document.createElement('a');
					attachmentLink.href = URL.createObjectURL(new Blob([attachment.content], { type: attachment.mimeType }));
					// attachmentLink.download = attachment.filename || 'attachment';
					attachmentLink.setAttribute('target', '_blank');
					attachmentLink.textContent = attachment.filename || `attachment (${attachment.mimeType})`
					attachmentsRef.current.appendChild(attachmentLink);
				}
			});
		}
	}, [ parsedEmail, attachmentsRef ]);

	return (
		<div className='document-view-email'>
			<div ref={ref} dangerouslySetInnerHTML={{ __html: (parsedEmail !== null && parsedEmail.html) }}></div>
			<p><strong>Attached Files</strong></p>
			<div ref={attachmentsRef} className='attachments' />
		</div>
	);
};

// Export
export default Email;
