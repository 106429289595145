import React, { useState } from "react";


//Style
import './SpecialProvisionsModal.scss';

const SpecialProvisionsModal = ({benefit}) => {

    const [isShowing, showModal] = useState(false);

    return(
        <>
            <button className='modal-link' onClick={() => showModal(true)}>{benefit.BenefitDescription}</button>

            <div className={'modal-overlay'} style={{ display: isShowing ? 'flex' : 'none'}} onClick={() => showModal(false)}>
                <div className={'modal-window'}>
                    <h2 key={0}>Special Provisions - {benefit.BenefitDescription}</h2>
                    { benefit.BenefitExclusions.map((exclusion, index) => (
                        <p key={index+1}>{exclusion.ExclusionText}</p>
                    ))}

                    <button className={'btn-primary btn-rounded'}>OK</button>
                </div>
            </div>
        </>

    )
}

export default SpecialProvisionsModal;