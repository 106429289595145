
// Externals
import { createSlice } from '@reduxjs/toolkit';
import config from '../../config';

// Utils
import { toTitleCase } from '../../util/formats';

// Actions 
import { 
	getCustomerDetail,
	updateContactField,
	clearContact,
	updateCustomerContacts,
	updateContactCopyResidentialToPostal,
	updateContactFromAusPostFormattedAddress,
	resetPendingUpdate } from '../actions/contact';

// Initial State
const initialState = {
	loading: false,
	FirstName: null,
	LastName: null,
	ResidentialAddress: {},
	PostalAddress: {},
    MobilePhone: null,
    HomePhone: null,
	Email: null,
	pendingUpdate: {
		ClientId: null,
		Email: null,
		HomePhone: null,
		MobilePhone: null,
		ResidentialAddress: {
			AddressLine: null,
			Suburb: null,
			State: null,
			PostCode: null,
			City: null
		},
		PostalAddress: {
			AddressLine: null,
			Suburb: null,
			State: null,
			PostCode: null,
			City: null,
			CountryId: null
		},
		CustomPostaladdress: false,
		Errors: {}
	},
	showErrorMessage: false
};

// Contact Reducer
const contact = createSlice({
	name: 'contact',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		
		// Load the contact details
		builder.addCase(getCustomerDetail.pending, (state, action) => {
			state.loading = true;
		});
		builder.addCase(getCustomerDetail.rejected, (state, action) => {
			state.loading = false;
		});
		builder.addCase(getCustomerDetail.fulfilled, (state, action) => {

			// For Viewing Contact Details
			state.FirstName = toTitleCase(action.payload.FirstName);
    		state.LastName = toTitleCase(action.payload.LastName);
			state.ResidentialAddress = action.payload.ResidentialAddress;
			state.PostalAddress = action.payload.PostalAddress;
			state.PostalAddress.CountryId = action.payload.CountryId;
			state.HomePhone = action.payload.HomePhone.replace(/^0101010101$/, '');
			state.MobilePhone = action.payload.MobilePhone;
			state.Email = action.payload.Email;

			// Payload for Editing Contact Details
			state.pendingUpdate.ClientId = action.payload.ClientId;
			state.pendingUpdate.ResidentialAddress = action.payload.ResidentialAddress;
			state.pendingUpdate.PostalAddress = action.payload.PostalAddress;
			state.pendingUpdate.PostalAddress.CountryId = action.payload.CountryId;
			state.pendingUpdate.HomePhone = action.payload.HomePhone.replace(/^0101010101$/, '');
			state.pendingUpdate.MobilePhone = action.payload.MobilePhone;
			state.pendingUpdate.Email = action.payload.Email;
			
			state.loading = false;
		});

		// Reset the pendingUpdate contact fields
		builder.addCase(resetPendingUpdate, (state, action) => {
			// Payload for Editing Contact Details
			state.pendingUpdate.ClientId = state.ClientId;
			state.pendingUpdate.ResidentialAddress = state.ResidentialAddress;
			state.pendingUpdate.PostalAddress = state.PostalAddress;
			state.pendingUpdate.HomePhone = state.HomePhone;
			state.pendingUpdate.MobilePhone = state.MobilePhone;
			state.pendingUpdate.Email = state.Email;
		});

		// Update the pendingUpdate contact field from user provided data
		builder.addCase(updateContactField, (state, action) => {
			const keys = action.payload.name.split('.');
			if(action.payload.name === "PostalAddress.CountryId"){
				action.payload.value = parseInt(action.payload.value, 10);
			}
			if (keys.length === 2) {
				state.pendingUpdate[keys[0]][keys[1]] = action.payload.value;
			}
			else {				
				state.pendingUpdate[action.payload.name] = action.payload.value;
			}
		});

		// Copy the pending Residential address to the pending Postal address
		builder.addCase(updateContactCopyResidentialToPostal, (state, action) => {
			state.pendingUpdate.PostalAddress = state.pendingUpdate.ResidentialAddress;
			state.pendingUpdate.PostalAddress.CountryId = config.DEFAULT_COUNTRY_ID;
		});

		// Receive a user-selected Residential address from the "format" API
		builder.addCase(updateContactFromAusPostFormattedAddress, (state, action) => {
			if(action.payload.isResidential){
				state.pendingUpdate.ResidentialAddress = action.payload.address;
			} else {
				state.pendingUpdate.PostalAddress = action.payload.address
			}
			
		});
		
		// Save the contact details
		builder.addCase(updateCustomerContacts.pending, (state, action) => {
			state.loading = true;
			state.showErrorMessage = false;
		});
		builder.addCase(updateCustomerContacts.rejected, (state, action) => {
			state.loading = false;
			state.showErrorMessage = true;
		});
		builder.addCase(updateCustomerContacts.fulfilled, (state, action) => {
			state.loading = false;
			state.showErrorMessage = false;
		});

		// Clear the contacts (for Logout)
		builder.addCase(clearContact, (state, action) => {
			state.FirstName = initialState.FirstName;
			state.LastName = initialState.LastName;
    		state.HomePhone = initialState.HomePhone;
			state.MobilePhone = initialState.MobilePhone;
			state.Email = initialState.Email;
			state.ResidentialAddress = initialState.ResidentialAddress;
			state.PostalAddress = initialState.PostalAddress;
			state.showErrorMessage = initialState.showErrorMessage;
		});
	}

});

// Exports
export default contact.reducer;
