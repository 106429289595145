import { useEffect, useRef } from "react";

const useHasChanged = (value) => {
    const prevValue = usePrevious(value);
    return prevValue !== value;
}

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    
    return ref.current;
}

export default useHasChanged;