// Externals
import React from 'react';

// Utility
import { getProductTypeIcon } from '../../util/products';

// Styles
import './Title.scss';

// Policy Summary component
const Title = (props) => {
	const { policy } = props;
	const iconClass = getProductTypeIcon(policy.Product.ProductType.ProductTypeId);
	return (
		<div className='policy-title'>
			<span className={iconClass}></span>
			<h2>{policy.Product.ProductName}</h2>
		</div>
	);
};

// Exports
export default Title;
