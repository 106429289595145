// Externals
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { getPayNowPaymentControl } from '../../store/actions/payment';

// Selectors
import { selectedPayNowTransactionIDsSelector, 
	selectedPayNowTransactionIDsTotalAmountSelector,
	payNowGatewaySelector } from '../../store/selectors/payment';

// Components
import Spinner from '../../components/Widgets/Spinner';
import FlashMessage from '../../components/Widgets/FlashMessage';

// Utility
import { formatMoneyWithDecimal } from '../../util/formats';
import { addMaskAfterFormLoad } from '../../util/domUtils'

// Styles
import './MakePayment.scss';

// Capture credit card details
const MakePayment = (props) => {

	const dispatch = useDispatch();
	const { policyNumber } = useParams();

	const loading = useSelector(state => state.payment.loading);
	const totalAmount = useSelector(selectedPayNowTransactionIDsTotalAmountSelector);
	const selectedTransactionIDs = useSelector(selectedPayNowTransactionIDsSelector);

	const gateway = useSelector(payNowGatewaySelector);
	const [ componentLoaded, setComponentLoaded ] = useState(false);
	const [ showError, setShowError ] = useState(false);

	const handleBack = props.handleBack;

	useEffect(() => {
		dispatch(getPayNowPaymentControl(policyNumber));
	}, [ policyNumber, dispatch ]);

	useLayoutEffect(() => {
		if (gateway && gateway.componentUrl && gateway.authToken !== null) {
			// Check for existing script tag, if not present + we have the url for the script, then insert 
			if (!document.querySelector(`script[src="${gateway.componentUrl}"]`)) {
				const script = document.createElement('script');
				script.src = gateway.componentUrl;
				script.async = true;
				script.onload = () => {
					setComponentLoaded(true)
					addMaskAfterFormLoad('form', 'input');
				};
				document.head.appendChild(script);
			}
			else {
				setComponentLoaded(true);
				addMaskAfterFormLoad('form', 'input');
			}
		}
	}, [ gateway ]);

	useEffect(() => {
		window.scrollTo({ top: 0, behaviour: 'smooth'});	
	}, [ loading, componentLoaded ]);

	const handleFormSubmit = () => {
		if(document.querySelector('form[data-quickstream-api="creditCardForm"] button[type="submit"]')){
			document.querySelector('form[data-quickstream-api="creditCardForm"] button[type="submit"]').click();
			window.scrollTo({ top: 0, behaviour: 'smooth'});
		}
		else {
			console.error('Realtime payment component is not loaded')
			setShowError(true);
		}		
	}

	return (<>

		{ loading && <Spinner />}

		{ !loading && showError && (
			<FlashMessage message='An error occured processing your payment' error={true} />
		) }

		{ !loading && !showError && componentLoaded && (<>
						
			<div className='total-outstanding'>
				<span>Total Outstanding</span>
				<span className='total-amount'>{formatMoneyWithDecimal(totalAmount)}</span>
			</div>

			<p>Please provide your credit card details.</p>

			<div className='payment-detail-form'>

				<payment-realtime 
					gfsauthtoken={gateway.authToken }
					quoteid={gateway.quoteID} 
					paymentapiurl={gateway.apiUrl}
					actionurl={window.location.href.split('?')[0]}
					transactionamount={totalAmount}
					clientipaddress={gateway.clientIPAddr}
					passthroughparamobject={selectedTransactionIDs.join()} />

			</div>

			<div className='terms-container'>
				<p>Please note: These payment details will not change your existing payment method for this policy.</p>
			</div>

			<div className='action-buttons'>
				<button className='form-btn btn btn-rounded' type="submit" onClick={handleFormSubmit}>Pay Now &gt;</button>
				<button className='form-btn btn btn-rounded cancel' onClick={handleBack}>Back</button>	
			</div>

		</>)}
	</>)
}

export default MakePayment;

