// Externals
import React from 'react';

// Components
import Container from '../components/Layout/Container';

// 404 Error Page
const Error404 = () => {

	return (
		<Container>
			<div className='page-error404'>

				<h2>Page not found</h2>

				<p>Sorry, but the page you were trying to view cannot be found.</p>

				<ul>
					<li>a bad link from somewhere else,</li>
					<li>a mistyped address,</li>
					<li>a deleted page, or</li>
					<li>content that has moved elsewhere.</li>
				</ul>

				<p>Please use the menu to find what you are after.</p>

			</div>
		</Container>
	);
};

export default Error404;
