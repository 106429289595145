// Externals
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Actions
import { customerLogout } from '../store/actions/auth';

// Logout container
const Logout = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(customerLogout());
	}, [ dispatch ]);

	return null;
};

// Exports
export default Logout;
