const sortDate = (x, y, ascending) => {
    var retVal = ascending ? 1 : -1

    if (x > y) return retVal;
    else if (x < y) return retVal * -1;
    else return 0;
}

export const sortDateAscending = (x, y) => {
    return sortDate(x, y, true);
}

export const sortDateDescending = (x, y) => {
    return sortDate(x, y, false);
}