// Externals
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import Container from '../Layout/Container';

// Styles
import './PolicyHeader.scss';

// 
const PolicyHeader = (props) => {
    const { policyNumber } = useParams();
	const policy = useSelector(state => state.policy.policySummaries[policyNumber]);
    return (
        <div className='policy-header'>
            <Container>
                <span className='product-name'>{policy.ProductName}</span>
                <span className='policy-number'>Policy No. {policy.PolicyNumber}</span>
            </Container>
        </div>
    )
}

export default PolicyHeader;
