// Externals
import React from 'react';

// Assets 
import { ReactComponent as SpinnerSVG } from '../../static/spinner.svg';

// Styles
import './Spinner.scss';

// Spinner component
const Spinner = () => {
	return (
		<div className='spinner'>
			<SpinnerSVG />
		</div>
	);
};

// Exports
export default Spinner;
