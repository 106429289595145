// Externals
import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Selectors
import { validPoliciesSelector } from '../store/selectors/policy';
import { headingSelector } from '../store/selectors/brand';

// Components
import Container from '../components/Layout/Container';
import OverdueBanner from '../components/Widgets/OverdueBanner';
import PolicyHeader from '../components/Widgets/PolicyHeader';
import DocumentList from '../components/Documents/List';
import DocumentView from '../components/Documents/View';

// Pages
import Error404 from '../pages/Error404';

// Documents page
const Documents = () => {
	const { policyNumber, documentNumber } = useParams();
	const validPolicies = useSelector(validPoliciesSelector);
	const pageHeading = useSelector(state => headingSelector(state, 'myDocuments')); 

	if (!validPolicies.includes(policyNumber)) {
		return <Error404 />;
	}
	else return (<>
		<PolicyHeader />

		<OverdueBanner />

		<Container>
			<div className='page-view-documents'>

				<h1>{pageHeading}</h1>

				{ !documentNumber && <DocumentList policyNumber={policyNumber} /> }

				{ documentNumber && <DocumentView policyNumber={policyNumber} documentNumber={documentNumber} /> }

			</div>
		</Container>

	</>);

};

// Exports
export default Documents;
export { DocumentView };
