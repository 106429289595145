// Externals
import React from 'react';

// Styles
import './FlashMessage.scss';

// FlashMessage component
const FlashMessage = (props) => {
	const { message, error } = props;
	const className = 'flash-message' + (error === true ? ' error' : '');

	return <>
		{ props.setHtml && message &&
			<div className={className} dangerouslySetInnerHTML={{ __html: message }}>
			</div>
		}

		{ props.setHtml === undefined && message &&
			<div className={className}>
				{message}
			</div>
		}

	</>
};

// Exports
export default FlashMessage;
