
export const headingSelector = (state, key) => state.brand.brandHeadings[key] ? state.brand.brandHeadings[key] : key;

export const policyChangeCTASelector = (state) => state.brand.brandMessages.changeToPolicyCTA;
export const chequeMethodChangeCTASelector = (state) => state.brand.brandMessages.chequeMethodChangeCTA;
export const loginFailureMessageSelector = (state) => state.brand.brandMessages.loginFailureMessage;
export const mobileHelpTextSelector = (state) => state.brand.brandMessages.mobileHelpText;
export const dobHelpTextSelector = (state) => state.brand.brandMessages.dobHelpText;
export const editContactDetailsTermsSelector = (state) => state.brand.brandMessages.editContactDetailsTerms;
export const contactNameChangeHelpTextSelector = (state) => state.brand.brandMessages.contactNameChangeHelpText;
export const contactUpdateFailureMessageSelector = (state) => state.brand.brandMessages.contactUpdateFailureMessage;
export const paymentUpdateFailureMessageSelector = (state) => state.brand.brandMessages.paymentUpdateFailureMessage;
export const footerDataSelector = (state) => {
    return {
        labels: state.brand.brandMessages.footerLinkLabels,
        destinations: state.brand.brandMessages.footerLinkDestinations,
        textHtml: state.brand.brandMessages.footerTextHtml
    }
}

export const brandPhoneSelector = (state) => [state.brand.brandPhone, "tel:" + state.brand.brandPhone.split(' ').join('')];

export const headerDataSelector = (state) => {
    return {
        headerText: state.brand.brandMessages.headerText,
        brandPhone: state.brand.brandPhone,
        brandLogo: state.brand.brandLogo,
        brandLogoAlt: state.brand.brandMessages.brandLogoAlt
    }
}

export const updateDetailMessagesSelector = (state) => {
    return {    
        contactDetails: state.brand.brandMessages.ctaContactDetails,
        paymentDetails: state.brand.brandMessages.ctaPaymentDetails,
        makePayment: state.brand.brandMessages.ctaMakeAPayment
    }
}

export const wereHereToHelpTextSelector = (state) => {
    return {
        title: state.brand.brandMessages.ctaWereHereToHelpTitle,
        text: state.brand.brandMessages.ctaWereHereToHelpText,
    }
}

export const makePaymentMessageSelector = (state) => {
    return {
        makePaymentFinalise: state.brand.brandMessages.makePaymentFinaliseMessage,
        makePaymentFailure: state.brand.brandMessages.makePaymentFailureMessage
    }
} 
export const beneficiariesFormLinkSelector = (state) => state.brand.brandMessages.beneficiariesFormLink;